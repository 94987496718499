<template>
  <div class="detail">
    <div>
      <div class="nav">首页 > <span class="click" @click="goBack">{{ this.$store.getters.info.fristName }}</span> > 文章详情</div>

      <div class="detailContent">
        <div class="title oneLine">{{ info.title }}</div>
        <div class="line2">
          <div>发布时间：{{ info.createTime }}</div>
          <div>书记：{{ info.firstUser }}</div>
        </div>
        <div style="margin-top:50px" class="rich" v-html="$setContent(info.context)" />
      </div>
      <div class="back center click" @click="goBack">返回栏目列表</div>
    </div>
  </div>
</template>

<script>
import { getUrlParams } from '@/utils/common.js'
export default {
  data() {
    return {
      info: {
        'title': '23123',
        'createTime': '2021年10月12日',
        'firstUser': '张武',
        'context': '123134523'
      },
      param: {
        id: ''
      }
    }
  },
  watch: {
    async $route(val) {
      this.param = val.query || {}
      this.getInfo()
    }
  },

  mounted() {
  },
  created() {
    this.param = getUrlParams()
    this.getInfo()
  },

  methods: {
    getInfo() {
      this.$parent.loading = true
      this.$store.dispatch('main/SecretaryGetFirstNewsInfo', { id: this.param.id }).then(res => {
        if (res.code === 0) {
          this.info = res.data
          this.$parent.loading = false
        }
      })
    },
    goBack() {
      this.$router.push({ path: '/Secretary' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .detail{
    .nav,.click{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .video{
      width: 830px;
      height: 466px;
      margin: 58px auto 50px auto;
      position: relative;
      video{
        width: 100%;
        height: 100%;
      }
      .start{
        position: absolute;
        left: 15px;
        bottom: 20px;
        width: 100px;
        height: 100px;
      }
    }
    .detailContent{
      margin-top: 19px;
      border: 1px solid #EEEEEE;
      padding: 31px 23px;
      margin-right: 24px;
      .title{
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .line2{
        display: flex;
        line-height: 14px;
        margin-top: 20px;
        div{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin-right: 20px;
        }
        &>div:nth-child(1){
          padding-right: 20px;
          border-right: 1px solid #999;

        }
      }
    }
    .back{
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 32px;
      width: 120px;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      margin-top: 30px;

    }

  }

</style>
